@import '../../../ui-styles/src/lib/sass/yo-mixins'
@import '../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../node_modules/bootstrap/scss/functions'
@import '../../../../node_modules/bootstrap/scss/variables'
@import '../../../ui-styles/src/lib/sass/yo-breakpoints'

.titleHeader
  margin-bottom: 1rem
  position: relative
  z-index: 1
  .titleHeaderHead
    border-bottom: 1px solid $gray-900
    padding-bottom: 5px
    margin-bottom: 10px
    display: flex
    flex-direction: row
    align-content: space-between
    justify-content: space-between
    @media screen and (max-width: $mobileActive)
      border-color: $gray-600
      margin-bottom: 0
    &.noUnderline
      border-bottom: none !important
    .actionsHeader
      text-align: right
      width: 100%
      > *
        margin-left: 5px
      &:first-child
        margin-left: 0
      a, :global(.btn)
        float: right
        margin-left: 5px

    h1
      display: inline-block
      font-size: 26px
      word-break: break-all
      min-width: 50%
      @media screen and (max-width: $mobileActive)
        font-size: 20px

      a
        color: $gray-700
        position: relative
        margin-right: 24px
        &:hover
          text-decoration: none
          color: $primary
        span
          &:after
            position: absolute
            content: "/"
            right: -16px

  .titleHeaderBody
    display: flex
    flex-direction: row
    justify-content: space-between
    .backLink
      font-size: 30px !important
      margin-top: -10px
    .breadcrumbs
      a
        color: $gray-700
        position: relative
        margin-right: 20px
        &:hover
          text-decoration: none
          color: $primary
        &:after
          position: absolute
          content: "/"
          right: -12px
        @media screen and (max-width: $mobileActive)
          font-size: 0.7rem
          margin-right: 8px
          &:after
            position: absolute
            content: "/"
            right: -6px
            top: 0
            font-size: 0.7rem


