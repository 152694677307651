@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import "../../node_modules/bootstrap/scss/_functions.scss"
@import "../../node_modules/bootstrap/scss/_variables.scss"

.dataObjectCard
  &:global(.card)
    box-shadow: none
    transition: 0.2s
    border-radius: 20px
    margin-top: 10px
