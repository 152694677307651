@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import "../../node_modules/bootstrap/scss/_functions.scss"
@import "../../node_modules/bootstrap/scss/_variables.scss"
@import '../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'


.searchForm
  margin-bottom: 30px
  justify-content: center
  @media screen and (max-width: $mobileActive)
    margin-bottom: 0
  .searchInputGroup
    &:global(.input-group)
      @include dropShadowSm()
      \:global(.input-group-text)
        background-color: $white !important
        border-color: $white
        font-size: 20px
      input
        padding: 0 10px
        border-color: $white


a.firebaseBtn
  // Need to specify line-height to get automatic vertical centering.  It should be $btn-line-height but that doesn't seem to work
  line-height: 30px
  img.firebaseLogo
    width: 25px
    height: 25px





