@import "../../../../../libs/ui-styles/src/lib/sass/yo-variables"
@import "../../../../../libs/ui-styles/src/lib/sass/yo-mixins"
@import '../../../../../node_modules/bootstrap/scss/functions'
@import '../../../../../node_modules/bootstrap/scss/variables'

.dangerModal
  \:global(.modal-body)
    position: relative
    z-index: 1
    h3
      margin-bottom: 30px
      text-transform: uppercase
      font-weight: $font-weight-bold
    div, small, p, li
      color: $white !important
      font-size: 20px
    &:before
      background-color: $red !important
      position: absolute
      display: flex
      flex-direction: column
      justify-content: center
      justify-items: center
      align-items: center
      align-content: center
      top: 0
      left: 0
      width: 100%
      height: 100%
      text-align: center
      color: tint-color($red, 10%)
      font-size: 100px
      background-repeat: repeat
      overflow: hidden
      line-height: 100px
      font-family: 'Font Awesome 5 Free'
      font-weight: 900
      content: "\f071"
      z-index: -1
