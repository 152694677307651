@import '../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'

table.table
  width: auto !important

  tr
    background-color: $white
    &:hover
      background-color: $gray-200
  td
    background-color: transparent
    &:hover
      background-color: transparent
    a, strong
      width: 100%
      display: inline-block
  tbody
    tr
      td
        vertical-align: baseline !important
        padding: 3px 5px !important

  &:global(.table-danger)
    \:global(.table-dark)
      th
        background-color: $red
        border-color: shade-color($red, 10%)
        button
          background-color: $red

  &:global(.table-warning)
    \:global(.table-dark)
      th
        background-color: $yellow
        border-color: shade-color($yellow, 10%)
        color: $gray-800
        button
          background-color: $yellow
          color: $gray-800

  &:global(.table-success)
    \:global(.table-dark)
      th
        background-color: $green
        border-color: shade-color($green, 10%)
        color: $gray-800
        button
          background-color: $green
          color: $gray-800

  &:global(.table-info)
    \:global(.table-dark)
      th
        background-color: $cyan
        border-color: shade-color($cyan, 10%)
        button
          background-color: $cyan

  th
    \:global(.btn)
      padding: 0
      border: 0
      font-weight: $font-weight-bold
      &:hover, &:focus, &:active
        background-color: initial !important
        border: 0 !important
        box-shadow: none !important

  &.minWidthContainer
    min-width: map-get($container-max-widths, "xl")

  &.mnw100
    min-width: 100%
