@import '../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import "../../../../../node_modules/bootstrap/scss/functions"
@import "../../../../../node_modules/bootstrap/scss/variables"

.navTabs
  border-bottom-width: 0 !important

  &:global(.success)
    \:global(.nav-item)
      :global(.nav-link)
        &:global(.active)
          background-color: $green
          span
            color: $white
        &:hover
          background-color: shade-color($green, 10%)
          span
            color: $white

  &:global(.warning)
    \:global(.nav-item)
      :global(.nav-link)
        &:global(.active)
          background-color: $yellow
          span
            color: $gray-700
        &:hover
          background-color: shade-color($yellow, 10%)
          span
            color: $gray-700

  &:global(.danger)
    \:global(.nav-item)
      :global(.nav-link)
        &:global(.active)
          background-color: $red
          span
            color: $white
        &:hover
          background-color: shade-color($red, 10%)
          span
            color: $white

  &:global(.info)
    \:global(.nav-item)
      :global(.nav-link)
        &:global(.active)
          background-color: $info
          span
            color: $white
        &:hover
          background-color: shade-color($info, 10%)
          span
            color: $white

  \:global(.nav-item)
    border-top-left-radius: 10px !important
    border-top-right-radius: 10px !important
    margin-right: 1px
    padding-bottom: 5px
    :global(.nav-link)
      border: none
      background-color: $gray-400
      span
        color: $gray-900
